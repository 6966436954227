//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "./networking/api";
import common from "./common/common";
export default {
  name: "IncentiveRedpacketIndex",
  data() {
    return {
      userInfo: {},
      date: this.$moment().format("YYYY-MM-DD"),
      dialogFormVisible: true,
      storeListLoading: false,
      storesOptions: [],
      stores: [],
      curStore: "",
      storeName: "",
      storeId: "",
      imageHost:api.imageHost,
      actList:[],
      actInfo:{
        id:'',
        validTime:'',
        actionName:''
      },
      actAccountInfo:{

      },
      showAct:false
    };
  },
  mounted: function () {
    window.document.title = "";
    this.requestUserInfo();
  },
  activated:function(){
    let that = this
    if(that.storeId && that.actInfo.id){
      that.getIncentiveRedPacketWalletAccount()
    }
  },
  methods: {
    goList(){
      this.$router.push({ path: '/incentiveRedpacket/list', query: { storeId:this.storeId,
        actionId:this.actInfo.id }});
    },
    closeAct(){
      this.showAct = false
    },
    openAct(){
      this.showAct = true
    },
    changeAct(item){
      this.actInfo = item
      this.getIncentiveRedPacketWalletAccount()
      this.showAct = false
    },
    showConsole(){
        var script = document.createElement('script');
        script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js'
        document.getElementsByTagName('body')[0].appendChild(script)
        script.onload = ()=>{
          var vConsole = new VConsole();
        }
    },
    requestUserInfo: function () {
      let that = this;
      this.storeListLoading = true;
      this.axios
        .get(api.getUserInfo)
        .then(function (response) {
          that.storeListLoading = false;
          if (common.success(response)) {
            let data = response.data.data;
            that.userInfo = data;
            if (!data.ownerShops) {
              alert("门店权限未配置");
              return;
            }

            let saleStore = data.attr && data.attr.saleStore || [];

            that.stores = [];
            let curStore = {
                storeName: saleStore[0].title,
                name: saleStore[0].storeId + "_",
                storeId: saleStore[0].storeId,
                brand: saleStore[0].brand,
              }
            for (let i = 0; i < saleStore.length; i++) {
              if(that.$route.query.storeId == saleStore[i].storeId){
                curStore = {
                  storeName: saleStore[i].title,
                  name: saleStore[i].storeId + "_",
                  storeId: saleStore[i].storeId,
                  brand: saleStore[i].brand,
                }
              }
              that.stores.push({
                storeName: saleStore[i].title,
                name: saleStore[i].storeId + "_",
                storeId: saleStore[i].storeId,
                brand: saleStore[i].brand,
              });
            }
            
            
            that.curStore = curStore;
            that.handleSelectStore();
            // }
          } else {
            alert("获取getUserInfo失败：" + response.data.reason);
          }
        })
        .catch(function (error) {
          alert("获取getUserInfo失败：" + error);
        })
        .then(function () {
          that.storeListLoading = false;
        });
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toUpperCase();
        this.storesOptions = this.stores.filter(function (v) {
          let result = v.name.toUpperCase().indexOf(needle) > -1;
          let result1 = v.storeName.indexOf(needle) > -1;
          if (result || result1) {
            return true;
          }
          return false;
        });
      });
    },

    handleSelectStore: function () {
      if (!this.curStore || this.curStore.length == 0) {
        this.$dialog.alert({
          message: "请先选择门店",
        });
        return;
      }
      this.storeName = this.curStore.storeName;
      this.storeId = this.curStore.storeId;
      this.dialogFormVisible = false;
      this.actList = []
      this.getData();
    },
    getData() {
      let _this = this
      let postData = {
          storeId:this.storeId,
          hrId:this.userInfo.hrid
      };
      this.axios
        .get(api.getIncentiveRedPacketActionList, {params:postData})
        .then((res) => {
          console.log('getIncentiveRedPacketActionList',res)
          if (res.data && res.data.code == 0) {
            res.data.data.map(v=>{
              v.validTime = _this.$moment(v.actionTimeBegin).format("yyyy/MM/DD") + '-' + _this.$moment(v.actionTimeEnd).format("yyyy/MM/DD")
            })
            this.actList = res.data.data || []
          }else{
            this.actList = []
          }
          if(this.actList.length == 0){
            this.$dialog.alert({
              message: "该门店未参与活动",
            });
            _this.actInfo = {
              id:'',
              validTime:'',
              actionName:''
            }
            return;
          }else{
            let actInfo = res.data.data.find(v=>{
              return _this.$route.query.actionId == v.id
            }) || res.data.data[0]
           _this.actInfo = actInfo
            _this.getIncentiveRedPacketWalletAccount()
          }
          _this.showAct = false
        });
    },
    getIncentiveRedPacketWalletAccount() {
      let _this = this
      let postData = {
        params: {
          storeId:this.storeId,
          actionId:this.actInfo.id
        }
      };
      this.axios
        .get(api.getIncentiveRedPacketWalletAccount, postData)
        .then((res) => {
          console.log('getIncentiveRedPacketWalletAccount',res)
          if (res.data && res.data.code == 0) {
            this.actAccountInfo = res.data.data || {}
          }
        });
    },
   
   
  }
};
